
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue'
import { wuRequest } from '@/service'
import { BASE_URL, url } from '@/service/config'
import { ElMessageBox, ElMessage } from 'element-plus'

//import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

export default defineComponent({
    components: {},
    setup() {
        let captcha = ref('')
        let t = reactive<any>({
            url,
            BASE_URL,
            src: '',
            lb: '',
            data: {
                enterprise: '', //企业
                tel: '', //电话
                name: '', //姓名
                content: '', //内容
                token: '',
                captcha: ''
            }
        })

        onMounted(async () => {
            // 保持菜单更新一直在顶
            document.body.scrollTop = document.documentElement.scrollTop = 0
            wuRequest.get({ url: 'https://gtchair.cn/api/contact' }).then((data) => {
                let res = data.data.data.data
                t.data.token = res.token
                t.lb = res.lb.url
                t.src = 'https://gtchair.cn/api/contact?token=' + res.token
            })
            // 取指定url的token，需要后台开起该字段  php代码 header('Access-Control-Expose-Headers:token');
            // var req = new XMLHttpRequest()
            // req.open('GET', 'https://gtchair.cn/api/contact', false)
            // req.send(null)
            // var headers = req.getAllResponseHeaders().toLowerCase()
            // let token = headers.slice(headers.indexOf('token:') + 'token:'.length + 1, -2)
            // t.token = token
            // t.src = 'https://gtchair.cn/api/contact?token=' + token
        })
        const submit = () => {
            if (t.data.name.length === 0 || t.data.tel.length === 0 || t.data.content.length === 0) {
                ElMessageBox.alert('手机、姓名、内容为必填', '高田人体工学椅', {
                    confirmButtonText: 'OK'
                })

                return false
            }
            // if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(t.data.tel)) {
            //     ElMessageBox.alert('电话格式不对', '高田人体工学椅', {
            //         confirmButtonText: 'OK'
            //     })
            //     return false
            // }
            wuRequest.post({ url: BASE_URL + '/contact', data: t.data }).then((d) => {
                if (d.data.code === 10001) {
                    ElMessageBox.alert('留言成功，我们会第一时间联系您', '高田人体工学椅', {
                        confirmButtonText: 'OK'
                    })
                } else {
                    ElMessageBox.alert(d.data.data.message, '高田人体工学椅', {
                        confirmButtonText: 'OK'
                    })
                }
            })
            return false
        }
        const Refresh = () => {
            t.src = t.src + '&math=' + Math.random()
        }
        return { ...toRefs(t), submit, Refresh, captcha }
    }
})
